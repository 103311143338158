<script setup lang="ts">
import useConfirmDialogState from './useConfirmDialogState';

const {
    isVisible,
    heading = '',
    body = '',
    confirmCallback,
    confirmLabel,
    cancelCallback,
    cancelLabel,
} = useConfirmDialogState();

function confirm() {
    confirmCallback.value();
    isVisible.value = false;
}

function cancel() {
    cancelCallback.value();
    isVisible.value = false;
}

/**
 * Dialog Handling
 * --------------------------------------------------------------------------------------------------
 */

const elDialog = useTemplateRef('elDialog');
watch(isVisible, (value) => {
    if (value) {
        nextTick(() => elDialog.value?.open());
    }
    else {
        elDialog.value?.close();
    }
});
</script>

<template>
    <BaseDialogConfirm
        v-if="isVisible"
        ref="elDialog"
        backdrop="dark"
        :heading="heading"
        :label-action-primary="confirmLabel"
        :show-action-secondary="true"
        :label-action-secondary="cancelLabel"
        :autofocus-secondary="true"
        @closed="cancel"
        @callback-primary="confirm"
        @callback-secondary="cancel"
    >
        {{ body }}
    </BaseDialogConfirm>
</template>
